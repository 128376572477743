import { take } from 'rxjs/operators';
import { UsersSelectors } from 'src/app/features/users/state/users.selector-types';
import { Router } from '@angular/router';
import { InvoicesActions } from 'src/app/features/invoices/state/invoices.action-types';
import { Store } from '@ngrx/store';
import { AppState } from './../../../../app.states';
import { InvoiceService } from 'src/app/features/invoices/services/invoice.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styleUrls: ['./checklist-modal.component.scss'],
})
export class ChecklistModalComponent implements OnInit {
  componentData;
  title = '';
  profile;
  percentage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private invoiceService: InvoiceService,
    private store: Store<AppState>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.componentData = _.cloneDeep(this.data);
    this.addCommentTootlip(this.componentData);
    this.addCavaliTooltip(this.componentData);

    switch (this.data.type) {
      case 'documents':
        this.title = 'Checklist de documentos';
        break;
      case 'risk':
        this.title = 'Checklist de riesgos';
        break;
      case 'cavali':
        this.title = 'Checklist de CAVALI';
        break;

      default:
        break;
    }

    this.store
      .select(UsersSelectors.profile())
      .pipe(take(1))
      .subscribe((profile) => {
        if (profile) {
          this.profile = profile;
        }
      });

    this.percentage = this.data.percentage;
  }

  drop(event) {
    if (this.data.type === 'documents') {
      this.invoiceService
        .updateCheck(
          this.data.invoice._id,
          event.card.status,
          this.data.type,
          event.card.type,
          event.card.subType,
          event.comment || ''
        )
        .subscribe((res) => {
          let result = Object(res);
          this.percentage = result.checklist.find(
            (ch) => ch.type == 'documents'
          ).percentage;

          this.updateComment(this.componentData, event);
        });
    } else {
      this.invoiceService
        .updateCheck(
          this.data.invoice._id,
          event.card.status,
          this.data.type,
          event.card.type,
          undefined,
          event.comment || ''
        )
        .subscribe((res) => {
          this.updateComment(this.componentData, event);
        });

      this.percentage = `${Math.floor(
        (this.componentData.subChecks.filter(
          (s) => s.status == 'approved' || s.status == 'exception'
        ).length /
          this.componentData.subChecks.length) *
          100
      )}%`;
    }
  }

  goToDetail() {
    if (this.profile.role == 'admin') {
      if (this.data?.type === 'documents') {
        window.open(
          'https://lookerstudio.google.com/u/0/reporting/e87b7a98-c043-4c3a-9f7b-a0ce54ed8a19/page/bwSED',
          '_blank'
        );
      } else {
        this.router.navigate([]).then((result) => {
          window.open('/invoices/' + this.data?.invoice?._id, '_blank');
        });
      }
    } else {
      this.router.navigate([]).then((result) => {
        window.open(
          '/risk-debtors/' + this.data?.invoice?.debtor?._id,
          '_blank'
        );
      });
    }
  }

  statusEnabled() {
    return (
      this.data.invoice.status !== 'in process' &&
      this.data.invoice.status !== 'approved' &&
      this.data.invoice.status !== 'configured' &&
      this.data.invoice.status !== 'pending confirmation'
    );
  }

  statusEnabledRisk(){
    return (
      this.data.invoice.status !== 'in process' &&
      this.data.invoice.status !== 'approved' &&
      this.data.invoice.status !== 'configured' &&
      this.data.invoice.status !== 'pending confirmation' &&
      this.data.invoice.status !== 'for sale' &&
      this.data.invoice.status !== 'sale closed' &&
      this.data.invoice.status !== 'partially sold'
    );
  }

  addCommentTootlip(entity) {
    for (let index = 0; index < entity.subChecks.length; index++) {
      if (entity.subChecks[index].comment) {
        if(entity.subChecks[index].comment === 'taken by fund'){
          entity.subChecks[index].tooltipText = 'Lo tomará un fondo';
        } else if(entity.subChecks[index].comment === 'finsmart risk'){
          entity.subChecks[index].tooltipText = 'Riesgo finsmart';
        } else if(entity.subChecks[index].comment === 'on time'){
          entity.subChecks[index].tooltipText = 'Puntual';
        } else if(entity.subChecks[index].comment === 'early renewal'){
          entity.subChecks[index].tooltipText = 'Renovación anticipada';
        } else if(entity.subChecks[index].comment === 'reduction plan'){
          entity.subChecks[index].tooltipText = 'Plan de reducción';
        } else if(entity.subChecks[index].comment === 'overdraft'){
          entity.subChecks[index].tooltipText = 'Sobregiro';
        } else if(entity.subChecks[index].comment === 'pending invoice completion'){
          entity.subChecks[index].tooltipText = 'Factura pendiente de finalización';
        }else{
          entity.subChecks[index].tooltipText = entity.subChecks[index].comment;
        }
      }
    }
  }
  
  updateComment(entity, event) {
    for (let index = 0; index < entity.subChecks.length; index++) {
      if (entity.subChecks[index].type === event.card.type) {
        entity.subChecks[index].tooltipText = event.comment || '';
      }
    }
  }

  addCavaliTooltip(entity) {
    if (entity.type === 'cavali') {
      for (let index = 0; index < entity.subChecks.length; index++) {
        if (entity.subChecks[index].type == 'compliance') {
          entity.subChecks[index].tooltipText = entity.invoice.physicalInvoices
            .map((p) => {
              let status = '---';

              if (p.cavaliStatus == 'in progress') {
                status = 'Sin respuesta';
              }

              if (p.cavaliPayerResponse) {
                switch (p.cavaliPayerResponse) {
                  case 'express compliance':
                    status = 'Conformidad Expresa';
                    break;
                  case 'rectified compliance':
                    status = 'Subsanada';
                    break;
                  case 'disagreement':
                  case 'rectified disagreement':
                    status = 'Disconformidad';
                    break;
                  default:
                    status = 'Conformidad presunta';
                    break;
                }
              }

              if (status == '---' && p.cavaliStatus) {
                status =
                  p.cavaliStatus.charAt(0).toUpperCase() +
                  p.cavaliStatus.slice(1);
              }

              return `• ${p.code} \xa0\ \xa0\ (${status})`;
            })
            .join('\n');
        }
      }
    }
  }
}
