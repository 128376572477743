import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ChangeDetectorRef,
  DoCheck,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
  selector: "app-multiple-checkbox",
  templateUrl: "./multiple-checkbox.component.html",
  styleUrls: ["./multiple-checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleCheckboxComponent),
      multi: true,
    },
  ],
})
export class MultipleCheckboxComponent
  implements ControlValueAccessor, OnInit, DoCheck
{
  @Input() options: { value: string; label: string }[] = [];
  @Input() disabled: boolean = false;
  @Input() errorMessage: string = "Este campo es requerido";
  @Input() control: FormControl;

  selectedValues: string[] = [];
  isError: boolean = false;
  prevTouchedState: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.control) {
      this.checkControlStatus();

      this.control.statusChanges.subscribe(() => {
        this.checkControlStatus();
      });
    }
  }

  ngDoCheck() {
    if (this.control) {
      const isTouched = this.control.touched;
      if (isTouched !== this.prevTouchedState) {
        this.prevTouchedState = isTouched;
        this.checkControlStatus();
      }
    }
  }

  private checkControlStatus() {
    if (this.control) {
      this.isError =
        this.control.invalid && (this.control.touched || this.control.dirty);
      this.cdr.detectChanges();
    }
  }

  toggleCheckbox(value: string, isChecked: boolean): void {
    if (isChecked) {
      if (!this.selectedValues.includes(value)) {
        // @ts-ignore
        this.selectedValues = [...this.selectedValues, value];
      }
    } else {
      this.selectedValues = this.selectedValues.filter(
        (item) => item !== value
      );
    }

    const valueByComma = this.selectedValues.join(",");
    this.onChange(valueByComma);
    this.onTouched();

    if (this.control) {
      this.control.markAsTouched();
      this.checkControlStatus();
    }
  }

  isSelected(value: string): boolean {
    return this.selectedValues.includes(value);
  }

  writeValue(value: string): void {
    if (value) {
      this.selectedValues = value.split(",").filter((v) => v.trim().length > 0);
    } else {
      this.selectedValues = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
