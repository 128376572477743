import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AddBankDialogComponent } from "src/app/features/my-supliers/UI/organisms/add-bank-dialog/add-bank-dialog.component";
import { SuplierService } from "src/app/features/my-supliers/services/supliers.service";
import { OverlayService } from "src/app/shared/modules/overlay/services/overlay.service";
import { CurrencyNamePipe } from "src/app/shared/pipes/currency-name.pipe";
import { ShortenPipe } from "src/app/shared/pipes/shorten.pipe";
import { ClientService } from "src/app/features/my-clients/services/client.service";
import { AddContactDialogComponent } from "src/app/features/my-clients/UI/organisms/add-contact-dialog/add-contact-dialog.component";
import { AddContactConfirmationComponent } from "src/app/features/my-clients/UI/organisms/add-contact-confirmation/add-contact-confirmation.component";
import { InvoicesActions } from "../../../state/invoices.action-types";
import { InvoiceSelectors } from "../../../state/invoices.selector-types";

@Component({
  selector: "app-add-invoice-step3",
  templateUrl: "./add-invoice-step3.component.html",
  styleUrls: ["./add-invoice-step3.component.scss"],
})
export class AddInvoiceStep3Component implements OnInit {
  @Input() isLoggedInUserAnAdmin: boolean;
  @Input() userId: string;
  @Input() isConfirming: boolean;
  @Input() invoices = [];
  @Output() updateInvoices = new EventEmitter();
  companies = [];

  constructor(
    private supplierService: SuplierService,
    private clientService: ClientService,
    private currencyNamePipe: CurrencyNamePipe,
    private shortenPipe: ShortenPipe,
    private dialog: MatDialog,
    private overlayService: OverlayService
  ) {}

  async ngOnInit() {
    for (let index = 0; index < this.invoices.length; index++) {
      const invoice = this.invoices[index];
      const company = this.companies.find(
        (company) => company._id === invoice.companyId
      );

      if (!company) {
        this.companies.push({
          name: invoice.companyName,
          ruc: invoice.companyRuc,
          _id: invoice.companyId,
          availableCurrency: [invoice.currency],
        });
      } else if (!company.availableCurrency.includes(invoice.currency)) {
        company.availableCurrency.push(invoice.currency);
      }
    }
    for (let index = 0; index < this.companies.length; index++) {
      const company = this.companies[index];
      company.contacts = await this.fetchContacts(company);

      if (this.isConfirming) {
        if (company.availableCurrency.includes("pen")) {
          company.penBankAccounts = await this.fetchBanks(company._id, "pen");

          const penInvoice = this.invoices.find(
            (invoice: any) =>
              invoice.currency === "pen" && company._id === invoice.companyId
          );

          if (penInvoice?.supplierBankAccount) {
            company.supplierBankAccountPEN = penInvoice.supplierBankAccount;
          } else if (company.penBankAccounts.length === 1) {
            company.supplierBankAccountPEN = company.penBankAccounts[0]._id;
          }
        }
        if (company.availableCurrency.includes("usd")) {
          company.usdBankAccounts = await this.fetchBanks(company._id, "usd");
          const usdInvoice = this.invoices.find(
            (invoice: any) =>
              invoice.currency === "usd" && company._id === invoice.companyId
          );

          if (usdInvoice?.supplierBankAccount) {
            company.supplierBankAccountUSD = usdInvoice.supplierBankAccount;
          } else if (company.usdBankAccounts.length === 1) {
            company.supplierBankAccountUSD = company.usdBankAccounts[0]._id;
          }
        }
      }

      this.checkValidity();
    }
  }

  checkValidity() {
    const isValid = this.companies.every((company) => {
      let bankValidation = false;
      let contactValidation = false;

      if (this.isConfirming) {
        if (company.availableCurrency.includes("pen")) {
          if (company.supplierBankAccountPEN) {
            bankValidation = true;
          } else {
            bankValidation = false;
          }
        }
        if (company.availableCurrency.includes("usd")) {
          if (company.supplierBankAccountUSD) {
            bankValidation = true;
          } else {
            bankValidation = false;
          }
        }

        if (company.supplierContact) {
          contactValidation = true;
        }
      } else {
        bankValidation = true;
        if (company.debtorContact) {
          contactValidation = true;
        }
      }

      return bankValidation && contactValidation;
    });

    this.invoices.forEach((invoice) => {
      const company = this.companies.find(
        (company) => company._id === invoice.companyId
      );

      if (company.supplierBankAccountPEN || company.supplierBankAccountUSD) {
        invoice.supplierBankAccount =
          invoice.currency === "pen"
            ? company.supplierBankAccountPEN
            : company.supplierBankAccountUSD;
      }

      if (company.supplierContact) {
        invoice.supplierContact = company.supplierContact;
      }

      if (company.debtorContact) {
        invoice.debtorContact = company.debtorContact;
      }
    });

    if (isValid) {
      this.updateInvoices.emit(this.invoices);
    }
  }

  async fetchBanks(supplierId: string, currency: string) {
    if (this.isLoggedInUserAnAdmin) {
      const data = await this.supplierService
        .getBanksAdmin(supplierId, this.userId)
        .toPromise();

      if (data) {
        return data.bankAccounts
          .filter((bank: any) => bank.currency === currency)
          .map((bankAccount: any) => this.mapBankAccountsOptions(bankAccount));
      }
    } else {
      const data: any = await this.supplierService
        .getBanks(supplierId)
        .toPromise();
      if (data) {
        return data.bankAccounts
          .filter((bank: any) => bank.currency === currency)
          .map((bankAccount: any) => this.mapBankAccountsOptions(bankAccount));
      }
    }
  }

  mapBankAccountsOptions(bankAccount: any) {
    return {
      ...bankAccount,
      bankSummary: `
        ${bankAccount.name} 
        ${this.currencyNamePipe.transform(bankAccount.currency)}  
        ${this.shortenPipe.transform(bankAccount.number)}`,
    };
  }

  addBankAccount(company: any) {
    const dialogRef = this.dialog.open(AddBankDialogComponent, {
      minWidth: "448px",
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: {
        availableCurrency: company.availableCurrency,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.debtor = company._id;
        result.user = this.userId;

        this.supplierService
          .createBankAccount(result)
          .subscribe((bankAccount) => {
            if (bankAccount.currency === "pen") {
              company.penBankAccounts.push(
                this.mapBankAccountsOptions(bankAccount)
              );
              if (company.penBankAccounts.length === 1) {
                company.supplierBankAccountPEN = company.penBankAccounts[0]._id;
                this.checkValidity();
              }
            }

            if (bankAccount.currency === "usd") {
              company.usdBankAccounts.push(
                this.mapBankAccountsOptions(bankAccount)
              );
              if (company.usdBankAccounts.length === 1) {
                company.supplierBankAccountUSD = company.usdBankAccounts[0]._id;
                this.checkValidity();
              }
            }
          });

        // this.overlayService.startConfirmation(
        //   { ...result, supplier: company._id, user: this.userId },
        //   BankAccountItemOrganism,
        //   'details',
        //   'create',
        //   'Recuerda que la cuenta bancaria debe estar a tu nombre, si no será denegada. Revisa atentamente la información antes de confirmar, recuerda que eres responsable único de que la información brindada es la correcta.',
        //   BankAccountsActions.Saving,
        //   null,
        //   savingSuccessStateById,
        //   null,
        //   this.fetchBanks.bind(this)
        // );
      }
    });
  }

  async fetchContacts(company: any) {
    let contacts: any = [];
    if (this.isLoggedInUserAnAdmin) {
      if (!this.isConfirming) {
        contacts = await this.clientService
          .getContactsByClient(this.userId, company._id)
          .toPromise();
      } else {
        contacts = await this.supplierService
          .getAllContactsByClient(this.userId, company._id)
          .toPromise();
      }
    } else {
      if (!this.isConfirming) {
        contacts = await this.clientService
          .getAllContacts(company._id)
          .toPromise();
      } else {
        contacts = await this.supplierService
          .getAllContactsByClient(this.userId, company._id)
          .toPromise();
      }
    }
    if (contacts?.length) {
      if (this.isConfirming) {
        company.supplierContact = contacts[0]._id;
      } else {
        company.debtorContact = contacts[0]._id;
      }
    }

    return contacts;
  }

  async updateContacts(company: any) {
    console.log("fetched at", new Date().getTime());
    company.contacts = await this.fetchContacts(company);
    console.log(company.contacts);

    this.checkValidity();
  }

  addContact(company: any) {
    const data: any = {
      isConfirming: this.isConfirming,
      user: this.userId,
    };

    if (this.isConfirming) {
      data.supplier = company._id;
    } else {
      data.debtor = company._id;
    }

    const dialogRef = this.dialog.open(AddContactDialogComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      position: {
        left: "0",
      },
      data: data,
    });

    dialogRef.afterClosed().subscribe((contact: any) => {
      if (contact) {
        const payload = { ...contact, _id: this.userId };
        if (this.isConfirming) {
          payload.supplier = company._id;
        } else {
          payload.debtor = company._id;
        }

        this.overlayService.startConfirmation(
          payload,
          {
            component: AddContactConfirmationComponent,
            entity: { ...contact },
          },
          "details",
          "create",
          null,
          InvoicesActions.SaveContact,
          null,
          InvoiceSelectors.savingSuccessStateById,
          {
            onConfirm: {
              title: "Confirmación datos del contacto",
            },
            onSuccess: {
              showCheck: true,
              title: "Se agregó el contacto correctamente",
            },
          },
          this.updateContacts.bind(this, company)
        );
      }
    });
  }

  openIntercom() {
    (<any>window).Intercom("show");
  }
}
