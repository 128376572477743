import { AbstractControl } from '@angular/forms';

export function InvestmentFieldsValidator(control: AbstractControl) {
  if (control.parent) {
    console.log('executing InvestmentFieldsValidator')
    const investmentExperience = control.parent.get('investmentExperience')?.value;

    if (investmentExperience === true) {
      if (!control.value || control.value.trim() === '') {
        return { investmentFieldsRequired: true };
      }
    }
  }

  return null;
}
