import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule, OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule } from '@angular/material-moment-adapter'

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true
  };

@NgModule({
    imports: [
        MatInputModule, MatButtonModule, MatSidenavModule,
        MatToolbarModule, MatIconModule, MatDialogModule, MatTableModule, MatBottomSheetModule,
        MatSnackBarModule, MatProgressSpinnerModule,
        MatCheckboxModule, MatMenuModule, MatNativeDateModule, MatChipsModule,
        MatButtonToggleModule, MatCardModule, MatDatepickerModule,
        MatDividerModule, MatExpansionModule, MatGridListModule,
        MatListModule, MatPaginatorModule, MatProgressBarModule,
        MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule,
        MatSortModule, MatStepperModule, MatTabsModule, MatTooltipModule, MatFormFieldModule,
        MatAutocompleteModule, MatTreeModule,
        MatBadgeModule, CdkTreeModule, MatMomentDateModule,
        OverlayModule, PortalModule, ClipboardModule
    ],
    exports: [
        MatInputModule, MatButtonModule, MatSidenavModule,
        MatToolbarModule, MatIconModule, MatDialogModule, MatTableModule, MatBottomSheetModule,
        MatSnackBarModule, MatProgressSpinnerModule, MatCheckboxModule,
        MatMenuModule, MatNativeDateModule, MatChipsModule,
        MatButtonToggleModule, MatCardModule, MatDatepickerModule,
        MatDividerModule, MatExpansionModule, MatGridListModule,
        MatListModule, MatPaginatorModule, MatProgressBarModule,
        MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule,
        MatSortModule, MatStepperModule, MatTabsModule, MatTooltipModule, MatFormFieldModule,
        MatAutocompleteModule, MatTreeModule, MatBadgeModule, CdkTreeModule,
        OverlayModule, PortalModule, ClipboardModule, MatMomentDateModule
    ],
    providers: [{provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig}]
})


export class AngularMaterialModule { }
