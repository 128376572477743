<div class="header">
    <img src="../../../../../../assets/icons/savings.svg" alt="">
    <h1>Cuenta Inversionista</h1>
</div>
<p class="description">Completa los siguientes datos:</p>
<form [formGroup]="form">
    <app-select formControlName="idType" (OnSelectionChanged)="idTypeChanged($event)" [defaultOption]="'national id'"
        [options]="idOptions" valueField="value" textField="text" label="Tipo de documento"></app-select>

    <app-input formControlName="idNumber" label="Número de documento"></app-input>

    <p *ngIf="selectedIdType !== 'ruc'" class="size-and-format-warning u-mb-3">Sube una imagen de tu documento de
        identidad.</p>

    <div class="file-upload-layout">
        <!-- <app-upload *ngIf="selectedIdType === 'passport'" formControlName="idFrontPicture"
                initialLabel="Subir documento" loadedLabel="Documento cargado">
            </app-upload> -->
        <lib-file-upload label="Documento" [iconUpload]="false" internalSpacing="s"
            *ngIf="selectedIdType === 'passport'" formats="image+pdf" [getFileName]="true"
            [initialLabel]="'Foto del pasaporte'" formControlName="idFrontPicture">
        </lib-file-upload>
        <!-- <app-upload *ngIf="selectedIdType !== 'passport' && selectedIdType !== 'ruc'" formControlName="idFrontPicture"
                initialLabel="Subir parte delantera" loadedLabel="Parte delantera cargada"></app-upload> -->

        <lib-file-upload label="Documento" [iconUpload]="false" internalSpacing="s"
            *ngIf="selectedIdType !== 'passport' && selectedIdType !== 'ruc'" formats="image+pdf" [getFileName]="true"
            [initialLabel]="'Parte delantera'" formControlName="idFrontPicture">
        </lib-file-upload>


        <lib-file-upload label="Documento" [iconUpload]="false" internalSpacing="s"
            *ngIf="selectedIdType !== 'passport' && selectedIdType !== 'ruc'" formats="image+pdf" [getFileName]="true"
            [initialLabel]="'Parte trasera'" formControlName="idBackPicture">
        </lib-file-upload>

        <!-- <app-upload *ngIf="selectedIdType !== 'passport' && selectedIdType !== 'ruc'" formControlName="idBackPicture"
                initialLabel="Subir parte trasera" loadedLabel="Parte trasera cargada">
            </app-upload> -->
    </div>



    <div *ngIf="selectedIdType !== 'ruc'" class="image-info">
        <app-signup-side-image [image]="'../../../../../assets/icons/info-24px.svg'"
            [description]="'Solo sube archivos en formato jpg,  jpeg, png, no mayor a 5MB'">
        </app-signup-side-image>
    </div>

    <app-select formControlName="channel" [options]="channels" valueField="value" textField="label"
        label="¿Cómo te enteraste de nosotros?"></app-select>


    <app-select formControlName="segment" [options]="segments" valueField="value" textField="label"
        label="¿Cuánto consideras invertir?"></app-select>

    <div class="image-info-investment">
        <app-signup-side-image [image]="'../../../../../assets/icons/info-24px.svg'"
            [description]="'Esta información no se compartirá y nos servirá para calibrar las ofertas de inversión para ti.'">
        </app-signup-side-image>
    </div>

    <div class="investment-input-container">
        <p class="investment-input-header">¿Tienes experiencia invirtiendo en Factoring u otros activos?</p>
        <mat-radio-group
            class="investment-input-input"
            formControlName="investmentExperience">
            <mat-radio-button [value]="true"><span class="investment-input-text">Sí</span></mat-radio-button>
            <mat-radio-button [value]="false"><span class="investment-input-text">No</span></mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="form.get('investmentExperience')?.value">
        <div class="investment-input-container" style="margin-top: 30px;">
            <p class="investment-field-header">¿En cuáles de los siguientes instrumentos has invertido?</p>
            <div>
                <app-multiple-checkbox
                    formControlName="investmentFields"
                    [options]="investmentFields"
                    [control]="form.get('investmentFields')"
                    errorMessage="Seleccione al menos un campo de inversión">
                </app-multiple-checkbox>
            </div>
        </div>
    </div>

    <div class="investment-input-container" style="margin-top: 32px;">
        <p class="investment-input-header-money">Cuando inviertes, ¿el dinero proviene de ahorros, préstamos u otra fuente?</p>
        <app-select formControlName="moneySource" [options]="moneySource"
        valueField="value" textField="label" label="Selecciona la fuente del dinero"></app-select>
        <div *ngIf="form.get('moneySource')?.value === 'Otros'">
            <textarea
                [(ngModel)]="moneySourceOther"
                [ngModelOptions]="{standalone: true}"
                placeholder="Detalla aquí"
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"
                maxlength="500"
            ></textarea>
        </div>
    </div>

    <app-checkbox [content]="checkBoxLabel" formControlName="isNewsletter" color="accent"></app-checkbox>

    <div class="acceptCondition">
        <p>Al hacer clic en <span class="bold">"Crear cuenta"</span> aceptas el <a class="bold blue"
                href="https://storage.googleapis.com/finsmart-websitefiles/legal/Contrato%20del%20inversionista%20v3.2.docx.pdf"
                target="_blank"> Contrato del Inversionista.</a></p>
    </div>

    <div class="actionButtons center-dp">
        <fs-ui-button (click)="backward()" [label]="'Atrás'" [type]="'tertiary'"></fs-ui-button>
        <fs-ui-button (click)="submit()" [label]="'Crear Cuenta'"></fs-ui-button>
    </div>
</form>