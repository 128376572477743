<div *ngIf="isError && errorMessage" class="tiny error-message">
  {{ errorMessage }}
</div>

<div class="checkbox-list" [class.error]="isError">
  <mat-checkbox
    *ngFor="let option of options"
    [checked]="isSelected(option.value)"
    [disabled]="disabled"
    (change)="toggleCheckbox(option.value, $event.checked)"
    class="checkbox-item"
  >
    {{ option.label }}
  </mat-checkbox>
</div>
