import { Injectable } from '@angular/core';
import * as Big from 'big.js';
import { INVOICE_STATUS } from 'e2e/helpers/investment.helper';
import moment from 'moment';
import { Observable } from 'rxjs';
import { getDaysDiff, getTodayDateOnly } from 'src/app/shared/util/date.util';
import { environment } from 'src/environments/environment';
import { xml2json } from 'xml-js';
import { RESTService } from '../../../shared/services/rest.service';
import { formatFilter } from '../../../shared/util/api.util';
@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private rest: RESTService) {}
  stats;

  parseXmlInvoice(xml) {
    let parsedInvoiceJson;
    let xmlInJson;

    try {
      let fileBlob = xml.toString();
      xmlInJson = JSON.parse(xml2json(fileBlob, { compact: true, spaces: 0 }))[
        'Invoice'
      ];

      let invoicePaymentTerm = xmlInJson['cac:PaymentTerms']
        ? Array.isArray(xmlInJson['cac:PaymentTerms'])
          ? xmlInJson['cac:PaymentTerms'].some(
              (term) =>
                term['cbc:PaymentMeansID']?.['_text']?.trim() === 'Credito' ||
                term['cbc:PaymentMeansID']?.['_cdata']?.trim() === 'Credito'
            )
            ? 'Credito'
            : 'Contado'
          : xmlInJson['cac:PaymentTerms']['cbc:PaymentMeansID']['_text']?.trim()
        : 'Contado';

      let hasQuotas = false; //xmlInJson['cac:PaymentTerms']?.length > 2;

      if (Array.isArray(xmlInJson['cac:PaymentTerms'])) {
        hasQuotas =
          xmlInJson['cac:PaymentTerms'].filter((term) =>
            term['cbc:PaymentMeansID']?.['_text']
              ?.trim()
              ?.toLowerCase()
              .includes('cuota')
          ).length > 1;
      }

      const issueDate = xmlInJson['cbc:IssueDate']['_text']?.trim();

      let isSunatProcess = false;
      let paymentDate;
      let xmlPaymentDate;

      let paymentTermsList = Array.isArray(xmlInJson['cac:PaymentTerms'])
        ? xmlInJson['cac:PaymentTerms']
        : [xmlInJson['cac:PaymentTerms']];
      let paymentDateList = paymentTermsList
        ?.filter((p) => p['cbc:PaymentDueDate'])
        .map((p) => p['cbc:PaymentDueDate']);

      if (paymentDateList && paymentDateList.length) {
        xmlPaymentDate =
          paymentDateList[paymentDateList.length - 1]['_text']?.trim();

        if (invoicePaymentTerm === 'Credito') {
          paymentDate = paymentDateList.pop()['_text']?.trim();

          let momentIssue = moment(issueDate, 'YYYY-MM-DD');
          let momentPayment = moment(paymentDate, 'YYYY-MM-DD');

          isSunatProcess =
            momentIssue.isSameOrAfter(moment('2021-12-17', 'YYYY-MM-DD')) &&
            momentPayment.diff(momentIssue, 'days') > 7;
        }
      }

      const totalAmount =
        xmlInJson['cac:LegalMonetaryTotal']['cbc:PayableAmount'][
          '_text'
        ]?.trim();

      const invoiceXMLDetail = this.getInvoiceXMLDetail(
        xmlInJson['cac:InvoiceLine']
      );

      parsedInvoiceJson = {
        code: xmlInJson['cbc:ID']['_text']?.trim(),
        emissionDate: issueDate?.trim(),
        dueDate: xmlInJson['cbc:DueDate']?.['_text']?.trim(),
        currency: xmlInJson['cbc:DocumentCurrencyCode']['_text']
          ?.toLowerCase()
          ?.trim(),
        debtorCompanyName:  Array.isArray(xmlInJson['cac:AccountingCustomerParty']['cac:Party']
          ['cac:PartyLegalEntity']['cbc:RegistrationName']['_cdata']) 
          ? xmlInJson['cac:AccountingCustomerParty']['cac:Party']
            ['cac:PartyLegalEntity']['cbc:RegistrationName']['_cdata'].join(" ")
          : xmlInJson['cac:AccountingCustomerParty']['cac:Party']
            ['cac:PartyLegalEntity']['cbc:RegistrationName']['_cdata']?.trim()
          || xmlInJson['cac:AccountingCustomerParty']['cac:Party']
            ['cac:PartyLegalEntity']['cbc:RegistrationName']._text?.trim(),
        debtorRuc: xmlInJson['cac:AccountingCustomerParty']['cac:Party'][
          'cac:PartyIdentification'
        ]['cbc:ID']['_cdata']?.trim()
          ? xmlInJson['cac:AccountingCustomerParty']['cac:Party'][
              'cac:PartyIdentification'
            ]['cbc:ID']['_cdata']?.trim()
          : xmlInJson['cac:AccountingCustomerParty']['cac:Party'][
              'cac:PartyIdentification'
            ]['cbc:ID']['_text']?.trim(),
        issuerRuc: xmlInJson['cac:AccountingSupplierParty']['cac:Party'][
          'cac:PartyIdentification'
        ]['cbc:ID']['_cdata']?.trim()
          ? xmlInJson['cac:AccountingSupplierParty']['cac:Party'][
              'cac:PartyIdentification'
            ]['cbc:ID']['_cdata']?.trim()
          : xmlInJson['cac:AccountingSupplierParty']['cac:Party'][
              'cac:PartyIdentification'
            ]['cbc:ID']['_text']?.trim(),
        issuerCompanyName: xmlInJson['cac:AccountingSupplierParty'][
          'cac:Party'
        ]['cac:PartyLegalEntity']['cbc:RegistrationName']['_cdata']?.trim()
          ? xmlInJson['cac:AccountingSupplierParty']['cac:Party'][
              'cac:PartyLegalEntity'
            ]['cbc:RegistrationName']['_cdata']?.trim()
          : xmlInJson['cac:AccountingSupplierParty']['cac:Party'][
              'cac:PartyLegalEntity'
            ]['cbc:RegistrationName']['_text']?.trim(),
        amount: totalAmount,
        isSunatProcess,
        hasQuotas,
        xmlPaymentDate: xmlPaymentDate?.trim(),
        ...invoiceXMLDetail,
      };

      parsedInvoiceJson.isCash = invoicePaymentTerm === 'Contado';
      if (isSunatProcess) {
        const paymentTerms = xmlInJson['cac:PaymentTerms'];

        let netAmount = totalAmount;

        if (Array.isArray(paymentTerms)) {
          paymentTerms.forEach((p: any) => {
            if (p['cbc:PaymentMeansID']?.['_text']?.trim() === 'Credito') {
              netAmount = p['cbc:Amount']['_text']?.trim();
            }
          });
        } else {
          netAmount = paymentTerms['cbc:Amount']['_text']?.trim();
        }

        parsedInvoiceJson.paymentDate = paymentDate;
        parsedInvoiceJson.retentionAmount = (
          Number(totalAmount) - Number(netAmount)
        ).toFixed(2);
        parsedInvoiceJson.netAmount = netAmount;
      }
    } catch (ex) {
      console.log(ex);
      console.error(
        'Unable to process this xml as an invoice (key extraction)'
      );
    } finally {
      return parsedInvoiceJson || null;
    }
  }

  parseXmlCreditNote(xml: any) {
    let parsedInvoiceJson;

    try {
      let fileBlob = xml.toString();
      let xmlInJson = JSON.parse(
        xml2json(fileBlob, { compact: true, spaces: 0 })
      )['CreditNote'];

      const code = xmlInJson['cbc:ID']['_text'];
      const issueDate = xmlInJson['cbc:IssueDate']?.['_text'];
      const relatedInvoiceCode = xmlInJson['cac:BillingReference'][
        'cac:InvoiceDocumentReference'
      ]['cbc:ID']['_text']?.replace(/ /g, '');
      const motive =
        xmlInJson['cac:DiscrepancyResponse']['cbc:Description']?.['_cdata'];
      const issuerRuc =
        xmlInJson['cac:AccountingSupplierParty']['cac:Party'][
          'cac:PartyIdentification'
        ]['cbc:ID']['_text'];
      const payerRuc =
        xmlInJson['cac:AccountingCustomerParty']['cac:Party'][
          'cac:PartyIdentification'
        ]['cbc:ID']['_text'];
      const netAmount = Array.isArray(xmlInJson['cac:PaymentTerms'])
        ? xmlInJson['cac:PaymentTerms'][0]?.['cbc:Amount']?.['_text']
        : xmlInJson['cac:PaymentTerms']?.['cbc:Amount']?.['_text'];
      const paymentDate = Array.isArray(xmlInJson['cac:PaymentTerms'])
        ? xmlInJson['cac:PaymentTerms']?.[
            xmlInJson['cac:PaymentTerms']?.length - 1
          ]?.['cbc:PaymentDueDate']?.['_text']
        : xmlInJson['cac:PaymentTerms']?.['cbc:PaymentDueDate']?.['_text'];
      const invoiceIssueDate =
        xmlInJson['cac:BillingReference']?.['cac:InvoiceDocumentReference']?.[
          'cbc:IssueDate'
        ]?.['_text'];

      parsedInvoiceJson = {
        code,
        issueDate,
        relatedInvoiceCode,
        issuerRuc,
        payerRuc,
        netAmount,
        paymentDate,
        motive,
        invoiceIssueDate,
      };
    } catch (ex) {
      console.log(ex);
      console.error(
        'Unable to process this xml as an invoice (key extraction)'
      );
    } finally {
      return parsedInvoiceJson || null;
    }
  }

  getInvoiceXMLDetail(xmlData) {
    if (!Array.isArray(xmlData)) {
      xmlData = [xmlData];
    }
    xmlData = xmlData.map((d) => {
      const description = Array.isArray(d['cac:Item']?.['cbc:Description'])
        ? d['cac:Item']?.['cbc:Description'][0]['_cdata']
        : d['cac:Item']?.['cbc:Description']?.['_cdata']
        ? d['cac:Item']?.['cbc:Description']?.['_cdata']
        : d['cac:Item']?.['cbc:Description']?.['_text'];
      const quantity = d['cbc:InvoicedQuantity']?.['_text'] || '0.00';
      const unitPrice =
        d['cac:Price']?.['cbc:PriceAmount']?.['_text'] || '0.00';
      const igvAmount =
        d['cac:TaxTotal']?.['cbc:TaxAmount']?.['_text'] || '0.00';
      const netAmount = d['cbc:LineExtensionAmount']?.['_text'] || '0.00';
      const totalAmount =
        !this.isNumber(netAmount) || !this.isNumber(igvAmount)
          ? '0.00'
          : (Number(netAmount) + Number(igvAmount)).toFixed(2);

      return {
        description,
        unitPrice,
        quantity,
        totalAmount,
        igvAmount,
        netAmount,
      };
    });

    const netAmountItem = xmlData
      .reduce((acc, cur) => acc + Number(cur.netAmount), 0)
      ?.toFixed(2);
    const igvAmountItem = xmlData
      .reduce((acc, cur) => acc + Number(cur.igvAmount), 0)
      ?.toFixed(2);
    const totalAmountItem = xmlData
      .reduce(
        (acc, cur) => acc + Number(cur.netAmount) + Number(cur.igvAmount),
        0
      )
      ?.toFixed(2);

    return {
      itemsDetail: xmlData,
      totalAmountItem,
      igvAmountItem,
      netAmountItem,
    };
  }

  downloadInvoiceFiles(invoiceId): Observable<any> {
    return this.rest.get(`/invoices/${invoiceId}/physical-invoices`);
  }

  downloadInvoiceDocument(invoiceId, type, flag?): Observable<any> {
    if (type === 'invoicing') {
      return this.rest.get(`/invoices/${invoiceId}/invoicing-files`);
    } else {
      if (flag !== undefined) {
        return this.rest.get(
          `/invoices/${invoiceId}/file?type=${type}&showDownloadSummarySheet=${flag}`
        );
      } else {
        return this.rest.get(`/invoices/${invoiceId}/file?type=${type}`);
      }
    }
  }

  downloadInvoicingsFiles(invoiceId: string): Observable<any> {
    return this.rest.get(`/invoices/${invoiceId}/invoicings-pdf`);
  }

  get(skip, limit, filter): Observable<any> {
    const filterHasValues = filter
      ? Object.values(filter).some((v) => {
          if (Array.isArray(v)) {
            return v.length > 0 ? true : false;
          } else {
            return !!v;
          }
        })
      : undefined;

    if (filterHasValues) {
      const filtersArray = [];
      const statusFilter = formatFilter(filter.statuses, 'status', 'in');
      const paymentDateFromFilter = formatFilter(
        filter.createdFrom,
        'paymentDate',
        'gte'
      );
      const paymentDateToFilter = formatFilter(
        filter.createdTo,
        'paymentDate',
        'lte'
      );
      const issuerFilter = formatFilter(filter.issuer, 'issuer', 'eq');
      const supplierFilter = formatFilter(filter.supplier, 'supplier', 'eq');
      const debtorFilter = formatFilter(filter.debtor, 'debtor', 'eq');
      const codeFilter = formatFilter(
        filter.invoiceCode?.toUpperCase(),
        'physicalInvoices.code',
        'eq'
      );
      let isConfirming;
      if (filter.hasOwnProperty('isConfirming')) {
        isConfirming = formatFilter(
          filter.isConfirming == 'true',
          'isConfirming',
          'eq'
        );
      }

      const bankAccountFilter = formatFilter(
        filter.bankAccount,
        'bankAccount',
        'eq'
      );

      if (statusFilter) filtersArray.push(statusFilter);
      if (paymentDateFromFilter) filtersArray.push(paymentDateFromFilter);
      if (paymentDateToFilter) filtersArray.push(paymentDateToFilter);
      if (issuerFilter) filtersArray.push(issuerFilter);
      if (debtorFilter) filtersArray.push(debtorFilter);
      if (codeFilter) filtersArray.push(codeFilter);
      if (bankAccountFilter) filtersArray.push(bankAccountFilter);
      if (supplierFilter) filtersArray.push(supplierFilter);
      if (isConfirming) filtersArray.push(isConfirming);

      if (filter.pendingTransfer) {
        filtersArray.push(formatFilter(false, 'advancePaymentDate', 'exists'));
        filtersArray.push(formatFilter('DESEMBOLSO', 'emailLog.type', 'eq'));
        filtersArray.push(formatFilter('disapproved', 'status', '!eq'));
      }

      return this.rest.get(
        `/invoices?skip=${skip}&limit=${limit}&filter=${filtersArray.join(',')}`
      );
    } else {
      return this.rest.get(`/invoices?skip=${skip}&limit=${limit}`);
    }
  }

  getOperationsFiltered(filter): Observable<any> {
    return this.rest.get(`/invoices?filter=${filter.join(',')}`);
  }

  getGroupInvoice(filter, invoice) {
    const filterHasValues = filter
      ? Object.values(filter).some((v) => {
          if (Array.isArray(v)) {
            return v.length > 0 ? true : false;
          } else {
            return !!v;
          }
        })
      : undefined;

    if (filterHasValues) {
      const filtersArray = [];
      const statusFilter = formatFilter(filter.statuses, 'status', 'in');
      const debtorFilter = formatFilter(filter.debtor, 'debtor', 'eq');
      const supplierFilter = formatFilter(filter.supplier, 'supplier', 'eq');
      const issuerFilter = formatFilter(filter.issuer, 'issuer', 'eq');
      const currencyFilter = formatFilter(filter.currency, 'currency', 'eq');
      const isConfirmingFilter = formatFilter(
        filter.isConfirming,
        'isConfirming',
        'eq'
      );

      if (statusFilter) filtersArray.push(statusFilter);
      if (issuerFilter) filtersArray.push(issuerFilter);
      if (debtorFilter) filtersArray.push(debtorFilter);
      if (isConfirmingFilter) filtersArray.push(isConfirmingFilter);
      if (currencyFilter) filtersArray.push(currencyFilter);
      if (supplierFilter) filtersArray.push(supplierFilter);
      if (filter.hasOwnProperty('isSunatProcess'))
        filtersArray.push(
          formatFilter(filter.isSunatProcess, 'isSunatProcess', 'eq')
        );
      if (filter.hasOwnProperty('paymentDate'))
        filtersArray.push(
          formatFilter(filter.paymentDate, 'paymentDate', 'eq')
        );

      return this.rest.get(`/invoices?filter=${filtersArray.join(',')}`);
    }
  }

  byId(id) {
    return this.rest.get(`/invoices/${id}`);
  }

  getCostValues(id, data) {
    return this.rest.patch(`/invoices/${id}/configuration`, data);
  }
  getPartialPayment(id, data) {
    return this.rest.patch(`/invoices/${id}/partial-payment`, data);
  }

  getConfiguration(id) {
    return this.rest.get(`/invoices/${id}/configuration`);
  }

  getAll(): Observable<any> {
    return this.rest.get(`/invoices`);
  }

  getInvoicingDetails(operationId: string, mode: string): Observable<any> {
    return this.rest.get(`/invoices/${operationId}/invoicings/${mode}`);
  }

  getAllOpportunities(): Observable<any> {
    return this.rest.get(`/opportunities`);
  }
  getAllOperations(): Observable<any> {
    return this.rest.get(`/dashboard`);
  }

  getAllCompanies(): Observable<any> {
    return this.rest.get(`/companies`);
  }
  getAllAccountManagers(): Observable<any> {
    return this.rest.get(`/account-managers`);
  }

  create(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.post(`/invoices`, entity);
  }

  forwardAnAnnouncement(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.patch(`/invoices/${entityId}`, entity);
  }
  createAnnouncement(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.post(`/invoices/${entityId}/announcements`, entity);
  }

  updateAnnouncement(entity): Observable<any> {
    return this.rest.patch(`/invoices/${entity._id}/announcements`, entity);
  }

  syncWithCavali(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.post(`/invoices/${entityId}/cavali-sync`, {});
  }

  update(entity): Observable<any> {
    if (entity.warrantyCheck) {
      const entityId = entity._id;
      let payload: any = { status: 'approved', mainType: 'warranty' };
      if (entity.newPaymentDate) {
        payload.paymentDate = entity.newPaymentDate;
      }

      return this.rest.patch(`/invoices/${entityId}/checklist`, payload);
    } else if (entity.invoicesCreditNote) {
      const entityId = entity.invoice._id;
      let payload: any = {
        invoicesCreditNote: { data: entity.invoicesCreditNote },
      };
      if (entity.newPaymentDate) {
        payload.paymentDate = entity.newPaymentDate;
      }

      return this.rest.patch(`/invoices/${entityId}/creditNote`, payload);
    } else {
      const entityId = entity._id;
      return this.rest.patch(`/invoices/${entityId}`, entity);
    }
  }

  setEstimatedTranferDate(entity, setTransferDate): Observable<any> {
    return this.rest.patch(`/invoices/${entity._id}`, {
      setTransferDate,
    });
  }

  getDebtorStatsByRUC(debtorRuc): Observable<any> {
    return this.rest.get(
      `/invoices/debtor-stats?filter=debtor:eq:${debtorRuc}`
    );
  }

  checkDuplicate(issuerRuc, debtorRuc, invoiceCode, isConfirming) {
    return this.rest.get(
      `/invoice?issuerRuc=${issuerRuc}&debtorRuc=${debtorRuc}&invoiceCode=${invoiceCode}&isConfirming=${isConfirming}`
    );
  }

  calculateROI(investmentAmount, tem, minimumDuration) {
    Big.DP = 40;
    Big.RM = 2;

    const dailyTEM = tem / 30;
    const ROI = new Big(investmentAmount ?? 0)
      .times(dailyTEM)
      .times(minimumDuration)
      .div(100)
      .toFixed(2);

    return ROI;
  }

  calculateCollectedDays(paymentDate, actualPaymentDate) {
    let diffDates;
    if (paymentDate != undefined && actualPaymentDate != undefined) {
      diffDates = getDaysDiff(paymentDate, actualPaymentDate);
    } else {
      diffDates = 0;
    }

    return Math.abs(diffDates);
  }

  save(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
      case 'createInvoice':
      case 'createInvoiceNew':
        return this.create(entity);
      case 'edit':
      case 'accept':
        return this.update(entity);
    }
  }

  saveAnnouncement(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.createAnnouncement(entity);
      case 'edit':
        return this.updateAnnouncement(entity);
    }
  }

  createAdditionalInvoicings(entity, mode): Observable<any> {
    const entityId = entity._id;

    return this.rest.post(`/invoices/${entityId}/invoicings`, {
      invoicings: entity.additionalInvoicings,
    });
  }

  forwardAnnouncement(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.forwardAnAnnouncement(entity);
    }
  }

  sendingToCavali(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.rest.post(`/invoices/${entity._id}/cavali`, entity);
      case 'edit':
        return this.rest.patch(`/invoices/${entity._id}/cavali`, entity);
    }
  }

  resendAnnouncementEmail(entity, mode): Observable<any> {
    switch (mode) {
      case 'create':
        return this.rest.post(
          `/invoices/${entity.invoiceId}/announcements`,
          entity
        );
    }
  }

  investmentPercentage(totalAmount, amountInvested) {
    Big.DP = 40;
    Big.RM = 2;

    const result = new Big(amountInvested)
      .div(totalAmount)
      .times(100)
      .toFixed(2);

    return result;
  }

  datesPercentage(initialDate, finalDate) {
    const todaysDateInPeru = getTodayDateOnly();
    let initDate = getDaysDiff(finalDate, todaysDateInPeru);

    const final = getDaysDiff(finalDate, initialDate);
    Big.DP = 40;
    Big.RM = 2;
    const result = new Big(initDate)
      .abs()
      .div(final)
      .abs()
      .minus(1)
      .abs()
      .times(100)
      .toFixed(2);

    return result;
  }

  calculateDebtorStatsByRUC(invoices, currency) {
    const todaysDateInPeru = getTodayDateOnly();
    //finalizedInvoicesCount
    const finalizedInvoicesCount = invoices.filter(
      (invoice) =>
        invoice.status === INVOICE_STATUS['FINALIZED'] &&
        invoice.currency === currency
    ).length;
    this.stats = {
      ...this.stats,
      finalizedInvoicesCount: finalizedInvoicesCount,
    };

    //invoicesAwaitingCollectionCount
    const invoicesAwaitingCollectionCount = invoices.filter(
      (invoice) =>
        invoice.status === INVOICE_STATUS['AWAITING COLLECTION'] &&
        invoice.currency === currency
    ).length;
    this.stats = {
      ...this.stats,
      invoicesAwaitingCollectionCount: invoicesAwaitingCollectionCount,
    };

    //delayedInvoicesCount
    const delayedInvoicesCount = invoices
      .filter(
        (invoice) =>
          invoice.status === INVOICE_STATUS['AWAITING COLLECTION'] &&
          invoice.currency === currency
      )
      .filter(
        (invoiceAwaitingCollection) =>
          getDaysDiff(invoiceAwaitingCollection.paymentDate, todaysDateInPeru) >
          8
      ).length;

    this.stats = { ...this.stats, delayedInvoicesCount: delayedInvoicesCount };

    //averageDelayOfFinalizedInvoices
    if (finalizedInvoicesCount > 0) {
      const agregatedDelay = invoices
        .filter(
          (invoice) =>
            invoice.status === INVOICE_STATUS['FINALIZED'] &&
            invoice.currency === currency
        )
        .reduce((totalDiff, invoice) => {
          //reverse it to get a positive number for displaying purposes

          const benchMarkDate = invoice.reprogrammingDate
            ? invoice.reprogrammingDate
            : invoice.paymentDate;

          const diff = getDaysDiff(benchMarkDate, invoice.actualPaymentDate);

          return totalDiff + (diff < 0 ? 0 : diff);
        }, 0);

      Big.DP = 40;
      Big.RM = 2;

      const averageDelay = new Big(
        agregatedDelay / finalizedInvoicesCount
      ).toFixed(2);

      let averageDelayOfInvoice =
        parseFloat(averageDelay) === 0
          ? '0 días'
          : parseFloat(averageDelay) === 1
          ? `1 día`
          : `${averageDelay} días`;

      this.stats = {
        ...this.stats,
        averageDelayOfFinalizedInvoices: averageDelayOfInvoice,
      };
    } else {
      this.stats = { ...this.stats, averageDelayOfFinalizedInvoices: '---' };
    }
    this.stats = { ...this.stats, currency: currency };

    const amountFinalized = new Big(
      invoices
        .filter(
          (invoice) =>
            invoice.status === INVOICE_STATUS['FINALIZED'] &&
            invoice.currency === currency
        )
        .reduce((acc, invoice) => {
          const amount = invoice.physicalInvoices.reduce(
            (acc, i) =>
              acc + (parseFloat(i.totalAmount) - parseFloat(i.retentionAmount)),
            0
          );
          return acc + amount;
        }, 0),
      2
    ).toFixed(2);
    this.stats = { ...this.stats, amountFinalized: amountFinalized };

    const amountDelayed = new Big(
      invoices
        .filter(
          (invoice) =>
            invoice.status === INVOICE_STATUS['AWAITING COLLECTION'] &&
            invoice.currency === currency
        )
        .filter(
          (invoiceAwaitingCollection) =>
            getDaysDiff(
              invoiceAwaitingCollection.paymentDate,
              todaysDateInPeru
            ) > 8
        )
        .reduce((acc, invoice) => {
          const amount = invoice.physicalInvoices.reduce(
            (acc, i) =>
              acc + (parseFloat(i.totalAmount) - parseFloat(i.retentionAmount)),
            0
          );
          return acc + amount;
        }, 0),
      2
    ).toFixed(2);
    this.stats = { ...this.stats, amountDelayed: amountDelayed };

    const amountInProgress = new Big(
      invoices
        .filter(
          (invoice) =>
            invoice.status === INVOICE_STATUS['AWAITING COLLECTION'] &&
            invoice.currency === currency
        )
        .reduce((acc, invoice) => {
          const amount = invoice.physicalInvoices.reduce(
            (acc, i) =>
              acc + (parseFloat(i.totalAmount) - parseFloat(i.retentionAmount)),
            0
          );
          return acc + amount;
        }, 0),
      2
    ).toFixed(2);
    this.stats = { ...this.stats, amountInProgress: amountInProgress };

    return this.stats;
  }
  calculateCollectedDateForClient(invoices) {
    const todaysDate = getTodayDateOnly(); //defaults to peru offset

    const newInvoices = invoices.map((invoice) => {
      const paymentDate = invoice.paymentDate;
      const minimumDuration = getDaysDiff(todaysDate, paymentDate);
      const toBeCollectedInClient =
        minimumDuration < -8
          ? 'En mora'
          : minimumDuration < 0 && minimumDuration > -9
          ? 'Plazo de gracia'
          : minimumDuration;
      invoice = { ...invoice, toBeCollectedInClient: toBeCollectedInClient };

      return invoice;
    });

    return newInvoices;
  }

  calculateComission(invoice) {
    let firtsOfDecember = new Date(2020, 11, 1).toISOString();
    Big.DP = 40;
    Big.RM = 1;

    let invoiceFinal = { ...invoice };
    if (invoiceFinal.createdAt < firtsOfDecember) {
      // console.log(" in if");
      const tax = invoiceFinal.commissionAmount
        ? new Big(invoiceFinal.commissionAmount ?? '').times(0.18)
        : 0;
      const revenueTax = invoiceFinal.commissionAmount
        ? new Big(invoiceFinal.commissionAmount ?? '').times(0.18)
        : 0;
      const salesPrice = invoiceFinal.commissionAmount
        ? new Big(invoiceFinal.commissionAmount ?? '').plus(tax)
        : 0;
      const balance = invoiceFinal.reservationAmount
        ? new Big(invoiceFinal.reservationAmount ?? '').minus(salesPrice)
        : 0;
      // console.log("invoiceFinal", invoiceFinal);
      invoiceFinal.finsmartRevenueTaxAmount = revenueTax?.toFixed(2);
      invoiceFinal.salePriceAmount = salesPrice?.toFixed(2);
      invoiceFinal.balanceToPaid = balance?.toFixed(2);

      return invoiceFinal;
    } else {
      if (
        invoiceFinal.investorsReturnAmount &&
        invoiceFinal.finsmartRevenueAmount &&
        invoiceFinal.finsmartRevenueTaxAmount &&
        invoiceFinal.reservationAmount
      ) {
        // console.log(" in else if");

        const factoringCost = new Big(invoiceFinal.investorsReturnAmount)
          .plus(invoiceFinal.finsmartRevenueAmount)
          .plus(invoiceFinal.finsmartRevenueTaxAmount);
        const balance = new Big(invoiceFinal.reservationAmount).minus(
          factoringCost
        );
        invoiceFinal.factoringCost = factoringCost?.toFixed(2);
        invoiceFinal.balanceToPaid = balance?.toFixed(2);
      } else {
        invoiceFinal.factoringCost = 0;
      }

      if (invoiceFinal.status == 'approved') {
        delete invoiceFinal.costWithoutIGV;
        delete invoiceFinal.finsmartCommissionAmount;
        delete invoiceFinal.commissionAdjustment;
      }

      return invoiceFinal;
    }
  }

  getAdvancePercentage(reservationPercentage) {
    Big.DP = 40;
    Big.RM = 2;

    if (!this.isNumber(reservationPercentage)) return undefined;

    const advancePercentage = new Big(100).minus(reservationPercentage);

    return advancePercentage;
  }

  getCommissionPercentage(tdmPercentage, duration) {
    Big.DP = 40;
    Big.RM = 2;

    if (!this.isNumber(tdmPercentage) || !this.isNumber(duration))
      return undefined;

    const commissionPercentage = Big(tdmPercentage).div(30).times(duration);
    return commissionPercentage;
  }

  getAmountFromPercentage(netAmount, commissionPercentage) {
    Big.DP = 40;
    Big.RM = 2;

    if (!this.isNumber(netAmount) || !this.isNumber(commissionPercentage))
      return undefined;

    const commissionAmount = Big(commissionPercentage)
      .div(100)
      .times(netAmount);
    return commissionAmount;
  }

  getInvestorsReturnAmount(advanceAmount, duration, tem) {
    Big.DP = 40;
    Big.RM = 2;

    if (
      !this.isNumber(advanceAmount) ||
      !this.isNumber(duration) ||
      !this.isNumber(tem)
    )
      return undefined;

    const investorsReturnAmount = Big(tem)
      .div(100)
      .div(30)
      .times(advanceAmount)
      .times(duration);
    return investorsReturnAmount;
  }

  getTem(tdmPercentage, distributionPercentage) {
    Big.DP = 40;
    Big.RM = 2;

    if (!this.isNumber(tdmPercentage) || !this.isNumber(distributionPercentage))
      return undefined;

    const normalizedTdm = tdmPercentage / 100;
    const value = Big(normalizedTdm).times(distributionPercentage).toFixed(4);
    return value;
  }

  getFinsmartRevenueAmount(commissionAmount, investorsReturnAmount) {
    Big.DP = 40;
    Big.RM = 2;

    if (
      !this.isNumber(commissionAmount) ||
      !this.isNumber(investorsReturnAmount)
    )
      return undefined;

    const finsmartRevenueAmount = Big(commissionAmount).minus(
      investorsReturnAmount
    );
    return finsmartRevenueAmount;
  }

  getTaxAmount(amount) {
    Big.DP = 40;
    Big.RM = 2;

    if (!this.isNumber(amount)) return undefined;

    const taxAmount = Big(amount).times(0.18);
    return taxAmount;
  }

  getBackupDays(monto, fecha, reserva, factor) {
    const dias = this.getInoviceDaysLeft(fecha);

    const backUpDays = Math.floor(
      (30 * monto -
        30 * (monto * (1 - this.toPercent(reserva))) -
        this.toPercent(1.18 * monto * factor)) /
        this.toPercent(1.18 * monto * factor) -
        dias
    );

    return !this.isNumber(backUpDays) ? 0 : backUpDays;
  }

  getBackupDaysNew(monto, igv, reserva, factor, fecha) {
    const dias = this.getInoviceDaysLeft(fecha);
    const backUpDays = Math.floor(
      (30 * (reserva - igv)) / (monto * this.toPercent(factor)) - dias
    );
    return !this.isNumber(backUpDays) ? 0 : backUpDays;
  }

  getBackupDaysNewV2(
    netAmount,
    tcem,
    reservationPercentage,
    distributionPercentage,
    reservationAmount,
    paymentDate,
    advancePaymentDate,
    commissionAdjustment,
    tem
  ) {
    const duration = moment(paymentDate).diff(
      moment(advancePaymentDate),
      'days'
    );

    if (commissionAdjustment) {
      reservationAmount -= Number(commissionAdjustment) * 1.18;
    }

    const pvNormal =
      ((netAmount * tcem) / 30) *
      (1.18 - (tem / 100 / tcem) * (1 - reservationPercentage) * 0.18);

    const pvNormalFee1 =
      (netAmount / 30) *
      (tcem * (1.18 - (tem / 100 / tcem) * (1 - reservationPercentage) * 0.18) +
        0.005);

    const pvNormalFee2 =
      (netAmount / 30) *
      (tcem * (1.18 - (tem / 100 / tcem) * (1 - reservationPercentage) * 0.18) +
        0.01);

    let backUpDays = 0;
    if (reservationAmount <= pvNormal * duration) {
      backUpDays =
        duration + (reservationAmount - pvNormal * duration) / pvNormal;
    } else if (
      reservationAmount > pvNormal * duration &&
      reservationAmount <= pvNormal * duration + pvNormalFee1 * 30
    ) {
      backUpDays =
        duration + (reservationAmount - pvNormal * duration) / pvNormalFee1;
    } else {
      backUpDays =
        duration +
        30 +
        (reservationAmount - pvNormal * duration - pvNormalFee1 * 30) /
          pvNormalFee2;
    }

    backUpDays = backUpDays - duration;
    return Math.trunc(backUpDays);
  }

  getInoviceDaysLeft(paymentDate, disbursementDate?, realpayemntDate?) {
    const priorDate = disbursementDate
      ? moment.utc(disbursementDate)
      : moment.utc();
    const laterDate = realpayemntDate
      ? moment.utc(realpayemntDate)
      : moment.utc(paymentDate);

    const days = laterDate
      .startOf('day')
      .diff(priorDate.startOf('day'), 'days');

    return days;
  }

  toPercent(num) {
    return this.round(num / 100, 4);
  }

  round(value, digits = 2) {
    const precision = Math.pow(10, digits);
    return Math.round(value * precision) / precision;
  }

  isNewFormula(createdAt) {
    const DATE_FILTER = moment.utc('2020-12-01', 'YYYY-MM-DD');
    return moment(createdAt).isAfter(DATE_FILTER);
  }

  getEmailPreview(entity) {
    let payload: any = {
      type: entity.type,
    };

    if (entity.notes) {
      payload.notes = entity.notes;
    }

    if (entity.file) {
      payload.file = entity.file;
    }

    if (entity.payerResponse) {
      payload.payerResponse = entity.payerResponse;
    }
    if (entity.invoices) {
      payload.invoices = entity.invoices;
    }

    if (entity.paymentDate) {
      payload.paymentDate = entity.paymentDate;
    }

    if (entity.physicalInvoices?.length) {
      payload.physicalInvoices = entity.physicalInvoices;
    }
    // if (entity.observations && entity.observations.length > 0) {
    const filter = formatFilter(entity.observations, '_id', 'in');

    return this.rest.post(
      `/invoices/${entity._id}/email?filter=${filter}`,
      payload
    );
    // }
    // else {

    //   return this.rest.post(`/invoices/${entity._id}/email`, payload);
    // }
  }

  sendEmail(entity, mode): Observable<any> {
    let entityId = entity._id;
    let payload: any = {
      cc: entity.result.cc,
      to: entity.result.to,
      type: entity.result.type,
      subject: entity.result.subject,
    };

    if (entity.result.invoices) {
      const newArray = entity.result.invoices.map((a) => Object.assign({}, a));
      let other = newArray.map((inv) => {
        let newObj = inv.physicalInvoices.map(({ flagEdit, ...rest }) => {
          return rest;
        });
        inv.physicalInvoices = newObj;
        return inv;
      });

      payload.invoices = other;
    }

    if (entity.result.notes) {
      payload.notes = entity.result.notes;
    }

    if (entity.result.file) {
      payload.file = entity.result.file;
    }

    if (entity.result.payerResponse) {
      payload.payerResponse = entity.result.payerResponse;
    }

    if (entity.result.invoices) {
      payload.invoices = entity.result.invoices;
    }

    if (entity.result.paymentDate) {
      payload.paymentDate = entity.result.paymentDate;
    }

    if (entity.result?.physicalInvoices?.length) {
      payload.physicalInvoices = entity.result.physicalInvoices;
    }

    const filter = formatFilter(entity.result.observations, '_id', 'in');

    // if (entity.result.observations && entity.result.observations.length > 0) {
    switch (mode) {
      case 'create':
        return this.rest.post(
          `/invoices/${entityId}/email?filter=${filter}`,
          payload
        );
      case 'edit':
        return this.rest.post(
          `/invoices/${entityId}/email?filter=${filter}`,
          payload
        );
    }
    // }
    // else {
    //   switch (mode) {
    //     case 'create':
    //       return this.rest.post(`/invoices/${entityId}/email`, payload);
    //     case 'edit':
    //       return this.rest.post(`/invoices/${entityId}/email`, payload);
    //   }
    // }
  }

  saveObservations(entity): Observable<any> {
    let entityId = entity.invoice;
    if (entity.comment === '') {
      entity.comment = ' ';
    }
    entity.type = 'client';
    return this.rest.post(`/invoices/${entityId}/invoice-observations`, entity);
  }
  getObservations(clientID): Observable<any> {
    return this.rest.get(`/invoice-observations?filter=client:in:${clientID}`);
  }
  getObservationsByCurrency(clientID, currency): Observable<any> {
    return this.rest.get(
      `/invoice-observations?filter=client:in:${clientID}, currency:in:${currency}, isDone:eq:false`
    );
  }

  updateObservation(obs, mode?) {
    let observation = { ...obs };
    let invoiceId = observation.invoice._id;
    let obsId = observation._id;
    return this.rest.patch(
      `/invoices/${invoiceId}/invoice-observations/${obsId}`,
      observation
    );
  }

  updateAnnouncementLog(invoiceId: any, origin = 'platform') {
    return this.rest.patch(`/invoices/${invoiceId}`, { origin });
  }

  getInvestors(entityId): Observable<any> {
    return this.rest.get(`/invoices/${entityId}/investments`);
  }

  getAnnouncements(entityId): Observable<any> {
    return this.rest.get(`/invoices/${entityId}/announcements`);
  }

  getAnnouncementFile(file) {
    return this.rest.get(`/announcements/file?file=${file}`);
  }

  getAllAnnouncementToForward(entity): Observable<any> {
    const entityId = entity._id;
    return this.rest.get(
      `/invoices/${entityId}/announcements/${entity?.announcementId}`,
      entity
    );
  }

  getAllInvoicesSameDebtorAndStatus(entityId): Observable<any> {
    return this.rest.get(`/invoice/${entityId}`);
  }

  getTelegramMessage(invoices, onSaleTime) {
    return this.rest.get(
      `/debtors/${invoices[0].debtor._id}/telegram?invoices=${invoices
        .map((i: any) => i._id)
        .join(',')}&onSaleTime=${onSaleTime}`
    );
  }

  updateCheck(invoiceId, status, mainType, type?, subType?, comment?) {
    let payload: any = {
      mainType,
      type,
      subType,
      status,
    };

    if (comment) {
      payload.comment = comment;
    }

    return this.rest.patch(`/invoices/${invoiceId}/checklist`, payload);
  }

  validateContact(contact, companyRuc) {
    return this.rest.patch(`/contacts/${contact?._id || contact.user?._id}`, {
      validate: true,
      companyRuc: companyRuc,
      jobTitle: contact.jobTitle,
      contactTypes: contact.contactTypes,
      name: contact.name,
      phone: contact.phone,
    });
  }

  unvalidateContact(contact, debtorId, companyRuc) {
    return this.rest.patch(`/contacts/${debtorId}`, {
      validate: false,
      companyRuc: companyRuc,
      email: contact.email,
    });
  }

  getDistribution(tea: any, tdm: any) {
    let normalizedTea = Number(tea) / 100;
    let normalizedTdm = Number(tdm) / 100;

    let tem = Number((Math.pow(normalizedTea + 1, 1 / 12) - 1).toFixed(6));

    return ((tem / normalizedTdm) * 100).toFixed(10);
  }

  getMinimumDuration(invoice) {
    return getDaysDiff(moment().startOf('day').toDate(), invoice.paymentDate);
  }

  getEvaluation(invoice: any) {
    try {
      const minimumDuration = this.getMinimumDuration(invoice);

      const commissionPercentage = this.getCommissionPercentage(
        invoice.tdmPercentage,
        minimumDuration
      );

      const commissionAmount = this.getAmountFromPercentage(
        invoice.netAmount,
        commissionPercentage
      );

      const advancePercentage = this.getAdvancePercentage(
        invoice.reservationPercentage
      );

      const advanceAmount = this.getAmountFromPercentage(
        invoice.netAmount,
        advancePercentage
      );

      const investorsReturn = this.getInvestorsReturnAmount(
        advanceAmount,
        minimumDuration,
        invoice.tem
      );

      const revenueAmount = this.getFinsmartRevenueAmount(
        commissionAmount,
        investorsReturn
      );

      let commissionAdjustment: any;

      if (!invoice.isConfirming) {
        const conditionsCheck = invoice?.checklist.find(
          (item: any) => item.type === 'conditions'
        );
        const isAfterDeployment = !(
          conditionsCheck?.status === 'approved' &&
          moment(conditionsCheck?.timestamp).isBefore(
            new Date(environment.deploymentDate)
          )
        );

        Big.DP = 40;
        Big.RM = 2;

        const threshold = invoice.currency === 'pen' ? 150 : 40;

        if (isAfterDeployment) {
          commissionAdjustment = new Big(threshold).minus(commissionAmount);

          if (commissionAdjustment.gt(0)) {
            commissionAdjustment = commissionAdjustment.toFixed(2);
          } else {
            commissionAdjustment = '0.00';
          }
        }
      }

      const taxAmount = this.getTaxAmount(revenueAmount);

      const reservationAmount = this.getAmountFromPercentage(
        invoice.netAmount,
        invoice.reservationPercentage
      );

      return this.isNewFormula(invoice.createdAt)
        ? this.getBackupDaysNewV2(
            invoice.netAmount,
            invoice.tdmPercentage / 100,
            invoice.reservationPercentage / 100,
            invoice.distributionPercentage / 100,
            Number(reservationAmount),
            invoice.paymentDate,
            moment().format('YYYY-MM-DD'),
            commissionAdjustment || invoice.commissionAdjustment,
            Number(invoice.tem)
          )
        : this.getBackupDays(
            invoice.netAmount,
            invoice.paymentDate,
            invoice.reservationPercentage,
            invoice.tdmPercentage
          );
    } catch (err) {
      console.log(err);
    }
    return 0;
  }

  isNumber(value) {
    return String(value)?.trim() !== '' && !isNaN(value);
  }

  getLatefee(invoiceId, actualPaymentDate) {
    return this.rest.get(
      `/invoices/${invoiceId}/late-fee?partialPaymentDate=${actualPaymentDate}`
    );
  }

  downloadOperationsExcel() {
    return this.rest.get('/client-operations/file');
  }
}
