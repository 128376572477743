import { UniqueDocumentValidator } from "./../../../../../shared/validators/unique-document.validator";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IDLengthValidator } from "../../../../../shared/validators/id-length.validator";
import { RequiredValidator } from "../../../../../shared/validators/required.validator";
import { IDFormatValidator } from "../../../../../shared/validators/id-format.validator";
import { GTMService } from "src/app/shared/services/gtm.service";
import { FormService } from "src/app/shared/services/form.service";
import { InvestmentFieldsValidator } from "src/app/shared/validators/investment-fields.validator";

@Component({
  selector: "app-sigup-investor-form",
  templateUrl: "./sigup-investor-form.component.html",
  styleUrls: ["./sigup-investor-form.component.scss"],
})
export class SigupInvestorFormComponent implements OnInit {
  @Output() previousStep = new EventEmitter();
  @Output() nextStep = new EventEmitter();

  form: FormGroup;
  idOptions = [
    { value: "national id", text: "DNI" },
    { value: "foreigner card", text: "Carnet de Extranjería" },
    { value: "passport", text: "Pasaporte" },
    { value: "ruc", text: "RUC" },
  ];
  segments;
  channels;
  moneySource = [];
  investmentFields = [];
  moneySourceOther = "";

  checkBoxLabel =
    "No quiero <span class='bold'>recibir comunicaciones</span> con fines comerciales.";
  selectedIdType;

  constructor(
    private formBuilder: FormBuilder,
    public gtmService: GTMService,
    private formService: FormService,
    private uniqueDocumentValidator: UniqueDocumentValidator
  ) {}

  ngOnInit(): void {
    this.channels = this.formService.getChannels();
    this.segments = this.formService.getInvestorSegments();
    this.moneySource = this.formService.getMoneySource();
    this.investmentFields = this.formService.getInvestmentFields();

    this.form = this.formBuilder.group({
      idType: ["", [Validators.required]],
      idNumber: [
        "",
        [RequiredValidator, IDFormatValidator, IDLengthValidator],
        [this.uniqueDocumentValidator.isUniqueDocument()],
      ],
      idFrontPicture: ["", [Validators.required]],
      idBackPicture: [""],
      channel: ["", [Validators.required]],
      segment: ["", [Validators.required]],
      isNewsletter: [""],
      investmentExperience: [true, [Validators.required]],
      investmentFields: ["", [InvestmentFieldsValidator]],
      moneySource: ["", [Validators.required]],
    });

    this.form.get("investmentExperience").valueChanges.subscribe(() => {
      this.form.get("investmentFields").updateValueAndValidity();
    });
  }

  submit() {
    this.updateMoneySourceInput();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    const formValue = { ...this.form.value };
    formValue.isNewsletter = !formValue.isNewsletter;

    if (!formValue.investmentExperience) {
      delete formValue.investmentFields;
    }

    if (this.form.valid) {
      this.nextStep.emit(formValue);
    } else {
      let event = {
        event: "[Platform][Next][Button]",
        _inputName: `Clicked Crear Mi Cuenta In Step 3 Error`,
        _inputCategory: `Signup Investor / Step 3`,
        _inputError: this.formService.getFormValidationErrors(this.form),
      };
      this.sendEvent(event);
    }
  }

  updateMoneySourceInput() {
    if (this.form.get("moneySource")?.value === "Otros") {
      this.form.patchValue({
        moneySource: this.moneySourceOther,
      });
    }
  }

  backward() {
    let event = {
      event: "[Platform][Next][Button]",
      _inputName: `Clicked Atrás In Step 3`,
      _inputCategory: `Signup Investor / Step 3`,
    };
    this.sendEvent(event);
    this.previousStep.emit();
  }

  idTypeChanged(newType) {
    this.form.patchValue({ idNumber: "" });
    this.selectedIdType = newType;
    if (this.selectedIdType !== "passport" && this.selectedIdType !== "ruc") {
      this.form.get("idBackPicture").setValidators([Validators.required]);
      this.form.get("idBackPicture").updateValueAndValidity();
    } else if (this.selectedIdType === "ruc") {
      this.form.get("idFrontPicture").setValidators([]);
      this.form.get("idBackPicture").setValidators([]);
      this.form.get("idBackPicture").updateValueAndValidity();
      this.form.get("idFrontPicture").updateValueAndValidity();
    } else if (this.selectedIdType === "passport") {
      this.form.get("idFrontPicture").setValidators([Validators.required]);
      this.form.get("idFrontPicture").updateValueAndValidity();
      this.form.get("idBackPicture").setValidators([]);
      this.form.get("idBackPicture").updateValueAndValidity();
    } else {
      this.form.get("idBackPicture").clearValidators();
      this.form.get("idBackPicture").updateValueAndValidity();
    }
  }

  sendEvent(event) {
    this.gtmService.newEvent(event);
  }
}
